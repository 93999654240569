import React, {Component} from 'react';
import Zoom from 'react-reveal/Zoom';

class Highlight extends Component {
  render() {
    return (
      <section className="hero is-medium">
        <div className="hero-body has-background-grey">
          <div className="container">
            <div className="columns">
              {this.props.data.map(row => {
                return (
                  <Zoom key={row.title}>
                    <div className="column">
                      <div className="columns is-multiline">
                        <div className="column is-full has-text-centered">
                          <span className="icon is-large has-text-grey-light">
                            <i className={row.fa_style + ' fa-3x'} />
                          </span>
                        </div>
                        <div className="column has-text-centered">
                          <h4 className="title is-4 has-text-white">
                            {row.title}
                          </h4>
                          <p className="has-text-grey-light">
                            {row.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Highlight;
