import React, {Component} from 'react';

class Teaser extends Component {
  componentDidMount() {
    this.nameInput.focus();
  }
  render() {
    return (
      <section
        className="section full-window-height has-background-black is-hidden-mobile"
        id="Teaser" onClick={()=>this.nameInput.focus()}>
        <div className="container has-text-grey-lighter">
          {this.props.print}
          {/*
          <span className="blinker">_</span>
          */}
          <input
            type="text"
            ref={input => {
              this.nameInput = input;
            }}
            onKeyPress={e => this.props.onKeyPress(e)}
          />
        </div>
      </section>
    );
  }
}

export default Teaser;
