import React, {Component} from 'react';
import Fade from 'react-reveal/Fade';
import SectionHeader from './Header';

class WorkExperience extends Component {
  constructor(props) {
    super(props);
    this.timeline = this.timeline.bind(this);
  }

  timeline(modifier) {
    return (
      <div className={'timeline ' + modifier}>
        {this.props.data.map((e, i) => {
          return (
            <Fade bottom key={i}>
              <div className="timeline-item">
                {e.thumbnail_url ? (
                  <div className="column">
                    <img src={e.thumbnail_url} alt="thumbnail for company" />
                  </div>
                ) : (
                  ''
                )}
                <div className="timeline-marker" />
                <div className="timeline-content">
                  <h4 className="heading">
                    {e.start_at} ~ {e.end_at}
                  </h4>
                  <h5 className="subtitle is-6">{e.position}</h5>
                  <h4 className="title is-4">{e.company}</h4>
                  <p>{e.summary}</p>
                </div>
              </div>
            </Fade>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <section className="section is-medium">
        <div className="container">
          <SectionHeader title="EXPERIENCE" subtitle="My working experience" />
          {this.timeline('is-centered is-hidden-mobile')}
          {this.timeline('is-hidden-desktop')}
        </div>
      </section>
    );
  }
}

export default WorkExperience;
