import React, {Component} from 'react';
import SectionHeader from './Header';
import Fade from 'react-reveal/Fade';

class Introduction extends Component {
  render() {
    return (
      <section className="section is-medium">
        <div className="container">
          <SectionHeader title="ABOUT" subtitle="Me" />
          <div className="columns">
            <div className="column is-half">
              <div className="columns">
                <div className="column">
                  <h4 className="title is-4">{this.props.data.title}</h4>
                  <p>{this.props.data.summary}</p>
                </div>
              </div>
              <div className="columns is-vcentered">
                <div className="column is-two-fifths">
                  <img
                    src={this.props.data.photo_url}
                    alt="profile"
                    className="profile-photo"
                  />
                </div>
                <div className="column">
                  <div className="columns">
                    <div className="column">
                      <b>Name:</b>
                      <br />
                      {this.props.data.name}
                      <br />
                      <b>Current Job:</b>
                      <br />
                      {this.props.data.job_title}
                      <br />
                      <b>Current Location:</b>
                      <br />
                      {this.props.data.address}
                      <br />
                      <b>Email Address:</b>
                      <br />
                      {this.props.data.email}
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      {this.props.data.github ? (
                        <a href={this.props.data.github} target="_blank" rel="noopener noreferrer">
                          <span className="icon is-large">
                            <i className="fab fa-2x fa-github" />
                          </span>
                        </a>
                      ) : (
                        ''
                      )}
                      {this.props.data.linkedin? (
                        <a href={this.props.data.linkedin} target="_blank" rel="noopener noreferrer">
                          <span className="icon is-large">
                            <i className="fab fa-2x fa-linkedin" />
                          </span>
                        </a>
                      ) : (
                        ''
                      )}
                      {this.props.data.google_scholar? (
                        <a href={this.props.data.google_scholar} target="_blank" rel="noopener noreferrer">
                          <span className="icon is-large">
                            <i className="fa fa-2x fa-graduation-cap" />
                          </span>
                        </a>
                      ) : (
                        ''
                      )}
                      {this.props.data.instagram ? (
                        <a href={this.props.data.instagram} target="_blank" rel="noopener noreferrer">
                          <span className="icon is-large">
                            <i className="fab fa-2x fa-instagram" />
                          </span>
                        </a>
                      ) : (
                        ''
                      )}
                      {this.props.data['500px'] ? (
                        <a href={this.props.data['500px']} target="_blank" rel="noopener noreferrer">
                          <span className="icon is-large">
                            <i className="fab fa-2x fa-500px" />
                          </span>
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="columns">
                <div className="column">
                  <h4 className="title is-4">Technical Skills</h4>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  {Object.keys(this.props.data.skills).map(key => {
                    return (
                      <Fade right key={key}>
                      <div className="columns is-vcentered is-mobile">
                        <div className="column">
                          <progress
                            className="progress is-small"
                            value={this.props.data.skills[key]}
                            max="100"
                          />
                        </div>
                        <div className="column is-one-third">{key}</div>
                      </div>
                      </Fade>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Introduction;
