import React, {Component} from 'react';
import SectionHeader from './Header';

class Education extends Component {
  render() {
    return (
      <section className="section is-medium">
        <div className="container">
          <SectionHeader title="EXPERIENCE" subtitle="Education" />
          <div className="steps" id="stepsDemo">
            {this.props.data.map((e, i) => {
              return (
                <div className="step-item" key={i}>
                  <div className="step-marker">{i+1}</div>
                  <div className="step-details">
                    <p className="step-title">{e.end_at}</p>
                    <h4 className="title is-5">{e.institute}</h4>
                    <p>
                      {e.degree}
                      {e.department ? (
                        <span>
                          <br />
                          {e.department}
                        </span>
                      ) : (
                        ''
                      )}{' '}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Education;
