import React, {Component} from 'react';

class SectionHeader extends Component {
  render() {
    return (
      <section className="section">
        <div className="container">
          <div className="column has-text-centered">
            <h5 className="title is-5">{this.props.title}</h5>
            <h2 className="subtitle is-2 is-spaced">{this.props.subtitle}</h2>
          </div>
        </div>
      </section>
    );
  }
}

export default SectionHeader;
